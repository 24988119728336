.sidebar{
	display:flex;
	flex-wrap:wrap;
	align-items:flex-start;
	& > .sticky {
		position: sticky;
		top: 20px;
	}
	& > h1{
		flex:0 0 100%;
		padding-left:250px;
		max-width:100%;
	}
	& > section{
		flex:0 0;
		flex-grow:1;
		min-width:0px;
	}
	& > aside{
		flex:0 0 250px;
		max-width:250px;
		position:relative;
		left:-20px;
	}
	&-right{
		margin-left:20px;
		& > h1{
			padding-left:20px;
			padding-right:250px;
		}
		& > aside{
			position:static;
			& > nav{
				border-left:none;
				border-right:4px solid $color-gris-50;
				padding-left:0;
			}
		}
		.bloc{
			margin-right:20px;
		}
	}
}

@include media-breakpoint-down(md) {
	.sidebar {
		& > .sticky {
			display: none;
		}
		& > section{
			min-width:initial;
		}
	}
}
.filters{
	.toggler{
		&:before{
			margin-right:7px;
		}
	}
	.toggle-group{
		padding-left:18px;
	}
	header{
		text-transform:uppercase;
	}
	.field,
	.field-wrap{
		margin-bottom:20px;
	}
	.field-wrap{
		.field{
			margin-bottom:0;
		}
	}
	.add-space{
		margin-bottom:2rem;
	}
	.no-margin-b{
		margin-bottom:0;
	}
	.no-margin-t{
		margin-top:0;
	}
	label,
	legend{
		text-transform:uppercase;
	}
	.field.react-dropdown-tree-select {
		max-width: 100%;
	}
	fieldset{
		legend{
			flex:0 0 100%;
			padding-right:0;
			text-align:left;
		}
		label{
			margin-bottom:0;
			text-transform:none;
		}
		.field,
		.field-wrap{
			margin-bottom:0;
			flex:0 0 100%;
			margin-top:0;
			.field{
				flex:1;
			}
		}
		&.date{
			legend{
				margin-bottom:0;
			}
			.react-datepicker-wrapper {
				width:100%;
				input {
					width:100%;
				}
			}
			.field-wrap {
				margin-right:0px;

				button.react-datepicker__navigation--next, button.react-datepicker__navigation--previous {
					border:0.45em solid transparent;
				}
				button.react-datepicker__navigation--next {
					border-left-color: #ccc;
				}
				button.react-datepicker__navigation--previous {
					border-right-color: #ccc;
				}
			}
		}
		&.statuts{
			legend{
				flex-grow:1;
				text-align:left;
			}
			& > div{
				display:flex;
				flex-wrap:wrap;
				flex:0 0 100%;
			}
			label{
				border-color:$color-blanc !important;
				background:$color-blanc;
				color:$color-blanc;
				flex:0 0 32%;
				padding-left:0;
				padding-right:0;
				margin-bottom:1px;
				height:2.5rem;
			}
			input[type="checkbox"]:checked + label,
			input[type="radio"]:checked{
				border-color:$color-gris-60;
			}
			input[type="checkbox"] + label,
			input[type="checkbox"] + label{
				padding-left:0;
				padding-right:0;
				white-space: pre;
			}
		}
		
	}
	.actif{
		label,
		legend{
			color:$color-vert-apave;
		}
	}
}
$maxwidth: 1600;

@for $i from 1 through $maxwidth {
    .w-#{$i}px {
        width: #{$i}px !important;
        flex:0 0 #{$i}px !important;
    }
    .offset-#{$i}px {
        padding-left:#{$i}px !important;
    }
}

$maxheight: 1600;

@for $i from 1 through $maxheight {
    .h-#{$i}px {
        height: #{$i}px !important;
        + .ck-editor .ck-content{
        	height: #{$i}px !important;
        }
    }
}

.h-auto{
    height:auto !important;
}
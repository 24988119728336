.home {
    .lastsheets a {
        text-decoration: none;
    }
    .conformitychart {
        .__react_component_tooltip {
            text-align: left;
            ul {
                margin-left: 15px;
            }
        }
        svg {
            overflow: visible;
            text {
                font-size: 0.7rem;
                color: $color-gris-texte;
            }
        }
    }
    .item-list.striped {
        .infos {
            width: 100%;
            a {
                text-decoration: none;
            }
        }
    }
}
.form {
    &.obligations-form {
        .table {
            display: table;
            fieldset {
                display: table-row;
                & > * {
                    display: table-cell;
                    width: 50%;
                }
            }
        }
    }
}
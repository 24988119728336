aside{
	& > nav{
		border-left:4px solid $color-gris-50;
		padding:0 10px;
		h3{
			text-transform:uppercase;
			font-size:1.3rem;
			margin-bottom:10px;
		}
		ul{
			list-style:none;
			font-size:1.1rem;
			margin:0;
			padding:0 0 0 20px;
			li{
				margin:0;
				padding:0;
				a{
					color:$color-gris-texte;
					margin-bottom:7px;
					display:inline-block;
					&:hover,
					&:active,
					&:focus{
						color:inherit;
					}
				}
			}
			&:last-child{
				li:last-child{
					a{
						margin-bottom:0;
					}
				}
			}
		}
	}
}
.droppable {
    background-color:$color-vert-2;

    & > ul {
        background-color:$color-blanc;
    }
}

.draggable {
    cursor:move;
}
.content-wrapper{
	flex-grow:1;
	margin-bottom:20px;
	background:$color-blanc;
	margin-left:20px;
	& > *{
		margin-left:20px;
		margin-bottom:20px;
		margin-right:20px;
		&:last-child{
			margin-bottom:0;
		}
	}
	& > h1{
		margin-bottom:0;
	}
	padding-bottom:20px;
}
.bloc{
	margin-bottom:20px;
	header{
		h1,
		h2,
		h3,
		h4,
		h5,
		h6{
			font-weight:600;
			font-size:1.3rem;
			margin:0;
			padding:0;
		}
		font-size:1.3rem;
		padding:10px 13px;
		background:$color-gris-60;
		color:$color-blanc;
		button.toggler:before{
			margin-right:7px;
		}
		select{
			min-height:25px;
		}
		button{
			position:relative;
			top:-2px;
		}
	}
	& > div {
		padding-left:13px;
		padding-right:13px;
	}
	section{
		& > div {
			padding-left:13px;
			padding-right:13px;
		}
	}
}

.bloc div[class*="border"] > *:last-child{margin-bottom:0;}
.bloc div[class*="border"]{padding-bottom:1rem;padding-top:1rem;}

.bloc div[class*="bg"] > *:last-child{margin-bottom:0;}
.bloc div[class*="bg"]{padding-bottom:1rem;padding-top:1rem;}
.dispatch-sheets {
    .dispatch-image {
        div.flex-label {
            width:195px;
        }
        label {
            display: none;
        }
        & > div > div {
            padding-left: 0px !important;
        }
        .filelist {
            padding-left: 0px;
        }
    }
}
.btn{
	font-size:1.1rem;
	padding:1rem 1.6rem;
	line-height:1rem;
  text-transform:uppercase;
  font-weight:600;
  display:inline-flex;
  justify-content:center;
  align-items:center;
  &:hover,
  &:focus{
    color:$color-blanc;
  }
	&.secondary{
		padding:0.5rem 1rem;
	}
  &.h25{
    height:2.5rem; 
    line-height:100%;
    padding:0 1.6rem;
    &.icon{
      i{
        font-size:1.3rem;
      }
    }
  }
  &.h30{
    height:3rem; 
  }
  &.h40{
    height:4rem; 
    padding:0 1rem;
  }
	& + .btn{
		margin-left:1rem;
	}
  &:disabled{
    opacity:1;
    background-color:#E1DFDF !important;
    border-color:#E1DFDF !important;
  }
  &.icon{
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    padding-left:0.5rem;
    i{
      font-size:1.8rem;
      margin-right:4px;
    }
    &-only{
      padding-right:0.5rem;
      i{
        margin-right:0;
      }
    }
  }
}

.btn-group {
  // Prevent double borders when buttons are next to each other
  > .btn:not(:first-child),
  > .btn-group:not(:first-child) {
    margin-left: -$btn-border-width;
  }

  // Reset rounded corners
  > .btn:not(:last-child):not(.dropdown-toggle),
  > .btn-group:not(:last-child) > .btn {
    margin-right:1px;
  }

  > .btn {
  	&+ .btn{
  		margin-left:0;
  	}
  }
}

button.toggler{
  border:none;
  background:none;
  padding:0;
  margin:0;
  color:inherit;
  text-transform:inherit;
  font-weight:inherit;
  transition:transform 0.3s ease;
  &:before,
  &:after{
    @extend .fontello-base;
    @extend .icon-general-carat;
    font-size:70%;
    position:relative;
    transform:rotate(270deg);
    top:2px;
  }
  &:after{
    display:none;
  }
  &.actif{
    &:before,
    &:after{
      transform:rotate(0);
      top:-2px;
      left:-2px;
    }
  }
  &.after{
    &:before{
      display:none;
    }
    &:after{
      content: '\e84b';
      display:inline-block;
      margin-left:4px;
    }
    &.actif:after{
      top:0;
    }
  }
}

button.sorting{
  display:inline-flex;
  flex-direction:column;
  padding:0;
  line-height:100%;
  i:first-child{
    transform:rotate(180deg);
  }
  i:last-child{
    position:relative;
    left:-4px;
    top:4px;
  }
  &.asc{
    i:first-child{
      color:$color-gris-texte;
    }
  }
  &.desc{
    i:last-child{
      color:$color-gris-texte;
    }
  }
}
$color-vert-apave:             #5bad27;
$color-gris-texte:             #4a4a4a;
$color-blanc:                  #ffffff;
$color-gris-10:                #f6f6f6;
$color-gris-20:                #eceaea;
$color-gris-25:                #e5e2e2;
$color-gris-27:                #d7d7d7;
$color-gris-30:                #cccccc;
$color-gris-35:                #c1c1c1;
$color-gris-40:                #c1bcbc;
$color-gris-45:                #bebbbb;
$color-gris-50:                #909090;
$color-gris-60:                #5a5a59;
$color-vert-1:                 #3a7018;
$color-vert-2:                 #95ce70;
$color-mauve:                  #9675a7;
$color-orange:                 #dc8d26;
$color-rouge:                  #ff0000;
$color-rouge-2:                #de5353;
$color-rouge-3:                #a81927;
$color-rouge-4:                #7e0f1a;
$color-bleu-1:                 #b5cee8;
$color-bleu-2:                 #90b7de;
$color-bleu-3:                 #618db8;
$color-bleu-4:                 #265a8e;
$color-batiment:               #75a5ac;
$color-certification:          #2a5b71;
$color-electricite:            #307a50;
$color-env-icat:               #8fa261;
$color-env-pa:                 #ac8b52;
$color-formation:              #ce4e2e;
$color-mecanique:              #d89611;
$color-performance:            #b5717d;
$color-pression:               #9e90a8;
$color-rayonnement:            #4c446e;
$color-ssh:                    #427cc8;
$color-new:                    #59bfbf;
$color-alert:                  #f6b533;
$color-error:                  #e93f33;
$color-valid:                  #a9d019;
$color-na:                     #bdb7b1;
$color-conformity-new:         #45bfbf;
$color-conformity-na:          #bdb7b1;
$color-conformity-info:        #dddddd;
$color-conformity-tospecified: #ffb607;
$color-conformity-nok:         #f52824;
$color-conformity-ok:          #a9d009;
$color-state-todecide:         #f52824;
$color-state-unnecessary:      #59bfbf;
$color-state-todo:             #f6b533;
$color-state-validated:        #a9d019;
$color-error-faded:            #ffdfcf;
$color-warning-faded:          #aadfcf;
$color-valid-faded:            #daedf4;

$colors: ();
$colors: map-merge(
  (
    "vert-apave":             $color-vert-apave,
    "gris-texte":             $color-gris-texte,
    "blanc":                  $color-blanc,
    "gris-10":                $color-gris-10,
    "gris-20":                $color-gris-20,
    "gris-25":                $color-gris-25,
    "gris-27":                $color-gris-27,
    "gris-30":                $color-gris-30,
    "gris-35":                $color-gris-35,
    "gris-40":                $color-gris-40,
    "gris-45":                $color-gris-45,
    "gris-50":                $color-gris-50,
    "gris-60":                $color-gris-60,
    "vert-1":                 $color-vert-1,
    "vert-2":                 $color-vert-2,
    "mauve":                  $color-mauve,
    "orange":                 $color-orange,
    "rouge":                  $color-rouge,
    "rouge-2":                $color-rouge-2,
    "rouge-3":                $color-rouge-3,
    "rouge-4":                $color-rouge-4,
    "bleu-1":                 $color-bleu-1,
    "bleu-2":                 $color-bleu-2,
    "bleu-3":                 $color-bleu-3,
    "bleu-4":                 $color-bleu-4,
    "batiment":               $color-batiment,
    "certification":          $color-certification,
    "electricite":            $color-electricite,
    "env-icat":               $color-env-icat,
    "env-pa":                 $color-env-pa,
    "formation":              $color-formation,
    "mecanique":              $color-mecanique,
    "performance":            $color-performance,
    "pression":               $color-pression,
    "rayonnement":            $color-rayonnement,
    "ssh":                    $color-ssh,
    "new":                    $color-new,
    "alert":                  $color-alert,
    "error":                  $color-error,
    "valid":                  $color-valid,
    "na":                     $color-na,
	'conformity-new':         $color-conformity-new,
	'conformity-na':          $color-conformity-na,
	'conformity-info':        $color-conformity-info,
	'conformity-tospecified': $color-conformity-tospecified,
	'conformity-nok':         $color-conformity-nok,
	'conformity-ok':          $color-conformity-ok,
    'state-todecide':       $color-state-todecide,
    'state-unnecessary':    $color-state-unnecessary,
    'state-todo':           $color-state-todo,
    'state-validated':      $color-state-validated
  ),
  $colors
);

$primary:       $color-vert-apave;
$secondary:     $color-gris-texte;
$success:       $color-valid;
$info:          $color-new;
$warning:       $color-alert;
$danger:        $color-error;
$light:         $color-gris-10;
$dark:          $color-gris-60;

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary":                $primary,
    "secondary":              $secondary,
    "success":                $success,
    "info":                   $info,
    "warning":                $warning,
    "danger":                 $danger,
    "light":                  $light,
    "dark":                   $dark,
    "vert-apave":             $color-vert-apave,
    "gris-texte":             $color-gris-texte,
    "blanc":                  $color-blanc,
    "gris-10":                $color-gris-10,
    "gris-20":                $color-gris-20,
    "gris-25":                $color-gris-25,
    "gris-27":                $color-gris-27,
    "gris-30":                $color-gris-30,
    "gris-35":                $color-gris-35,
    "gris-40":                $color-gris-40,
    "gris-45":                $color-gris-45,
    "gris-50":                $color-gris-50,
    "gris-60":                $color-gris-60,
    "vert-1":                 $color-vert-1,
    "vert-2":                 $color-vert-2,
    "mauve":                  $color-mauve,
    "orange":                 $color-orange,
    "rouge":                  $color-rouge,
    "rouge-2":                $color-rouge-2,
    "rouge-3":                $color-rouge-3,
    "rouge-4":                $color-rouge-4,
    "bleu-1":                 $color-bleu-1,
    "bleu-2":                 $color-bleu-2,
    "bleu-3":                 $color-bleu-3,
    "bleu-4":                 $color-bleu-4,
    "batiment":               $color-batiment,
    "certification":          $color-certification,
    "electricite":            $color-electricite,
    "env-icat":               $color-env-icat,
    "env-pa":                 $color-env-pa,
    "formation":              $color-formation,
    "mecanique":              $color-mecanique,
    "performance":            $color-performance,
    "pression":               $color-pression,
    "rayonnement":            $color-rayonnement,
    "ssh":                    $color-ssh,
    "new":                    $color-new,
    "alert":                  $color-alert,
    "error":                  $color-error,
    "valid":                  $color-valid,
    "na":                     $color-na,
	'conformity-new':         $color-conformity-new,
	'conformity-na':          $color-conformity-na,
	'conformity-info':        $color-conformity-info,
	'conformity-tospecified': $color-conformity-tospecified,
	'conformity-nok':         $color-conformity-nok,
	'conformity-ok':          $color-conformity-ok,
    'state-todecide':       $color-state-todecide,
    'state-unnecessary':    $color-state-unnecessary,
    'state-todo':           $color-state-todo,
    'state-validated':      $color-state-validated,
    'warning-faded':          $color-warning-faded,
    'valid-faded':            $color-valid-faded,
    'error-faded':            $color-error-faded
  ),
  $theme-colors
);

$yiq-text-dark:    $color-gris-60;

$body-bg:    $color-gris-10;
$body-color: $color-gris-texte;

$border-radius:               .25rem;
$border-radius-lg:            .3rem;
$border-radius-sm:            .2rem;
$border-color:$color-gris-25;

$font-size-base: 1.2rem;
@mixin bg-color-varient($color){
	color: color-yiq($color);
}

@each $color, $value in $theme-colors {
  .color-#{$color} {
    color:#{$value} !important;
  }
  .bg-#{$color} {
    /*color:color-yiq($value) !important;*/
  }
  input[type="checkbox"]:checked + label.bg-checked-#{$color},
  input[type="radio"]:checked + label.bg-checked-#{$color}{
  	background-color:#{$value} !important;
  	color:color-yiq($value) !important;
  }
  input[type="checkbox"]:checked + label.bg-#{$color}:before,
  input[type="radio"]:checked + label.bg-#{$color}:before{
  	color:#{$value} !important;
  }
  .dotted.color-#{$color}:before{
    background-color:#{$value} !important;
  }
}
.actions {
	display:flex;
	flex-wrap:wrap;
	align-items:center;
	justify-content:center;
	list-style:none;
	margin:0;
	padding:0;
	li{
		display:inline-flex;
		margin:0 2px;
		a,
		button{
			margin:0;
			padding:0;
			background:none;
			position:relative;
			border:none;
			color:$color-gris-50;
			i{
				color:inherit;
			}
			.counter{
				position:absolute;
				top:0;
				left:0;
				font-size:0.8rem;
			}
			&:hover,
			&:focus{
				color:$color-vert-apave;
			}
		}
	}
}
.login{
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	width:100%;
	height:100%;
	display:flex;
	justify-content:center;
	align-items:center;
	.form{
		min-width:600px;
	}
	h1{
		display:flex;
		div{
			display:flex;
			justify-content:center;
			align-items:center;
			&.main-name{
				padding-left:36px;
				text-transform:uppercase;
			}
		}
	}
}
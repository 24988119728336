.pagination-dot{
	display:block;
	content:"";
	border-radius:100%;
	background:$color-gris-30;
	position:absolute;
}

.pagination{
	display:flex;
	height:3.5rem;
	line-height:3.5rem;
	padding-bottom:10px;
	margin-bottom:20px;
	a{
		color:$color-gris-texte;
	}
	.pages{
		flex-grow:1;
		text-align:center;
		display:flex;
		justify-content:center;
		.arrows{
			a{
				margin:0 15px;
				&.first{
					margin-right:0;
				}
				&.last{
					margin-left:0;
				}
				font-size:1.5rem;
				font-weight:bold;
				i{
					display:inline-block;
					width:10px;
				}
			}
		}
		.parts{
			display:flex;
			justify-content:center;
			ul{
				margin:0;
				padding:0;
				display:flex;
				justify-content:center;
				list-style:none;
				height:2.5rem;
				line-height:2.5rem;
				li{
					a{
						font-size:1.5rem;
						display:flex;
						flex-direction:column;
						align-items:center;
						text-align:center;
						position:relative;
						margin:0 4px;
						height:2.5rem;
						line-height:2.5rem;
						&:after{
							@extend .pagination-dot;
							width:7px;
							height:7px;
							top:100%;
						}
						&:hover,
						&:focus{
							color:$color-vert-apave;
							text-decoration:none;
						}
					}
					&.act{
						a{
							color:$color-vert-apave;
							font-weight:bold;
							font-size:2rem;
							position:relative;
							top:-4px;
						}
					}
				}
			}
			.start{
				position:relative;
				padding-right:15px;
				&:after{
					@extend .pagination-dot;
					width:5px;
					height:5px;
					bottom:-6px;
					right:5px;
				}
				
			}
			.current{
				position:relative;
				padding-right:15px;
				padding-left:15px;
				&:before{
					@extend .pagination-dot;
					width:5px;
					height:5px;
					bottom:-6px;
					left:5px;
				}
				&:after{
					@extend .pagination-dot;
					width:5px;
					height:5px;
					bottom:-6px;
					right:5px;
				}
				
			}
			.end{
				position:relative;
				padding-left:15px;
				&:before{
					@extend .pagination-dot;
					width:5px;
					height:5px;
					bottom:-6px;
					left:5px;
				}
				padding-right:15px;
			}
		}
	}
	.nb-results{
		margin:0;
		padding:0 15px;
		label{
			@extend .sr-only;
		}
	}
	.infos{
		margin:0;
		padding:0 15px;
		font-weight:bold;
		font-size:1.3rem;
	}

}
.main{
	padding-top:5.5rem;
	position:absolute;
	top:0;
	bottom:0;
	overflow:hidden;
	width:100%;
}

.main-nav-opened{
	.main{
		/*overflow:auto;*/
	}
}

@import "main/main-nav";
@import "main/content";
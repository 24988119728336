.progress{
	height:2rem;
	overflow:visible;
	margin-top:20px;
	&-bar{
		position:relative;
		overflow:visible;
		&::before{
			content:attr(displayed-value);
			position:absolute;
			top:-1.5rem;
			width:100%;
			left:auto;
			right:auto;
			text-align:center;
			display:block;
			height:2rem;
			color:$color-gris-texte;
			font-size:1.1rem;
		}
		&.bg-blanc{
			border:1px solid $color-gris-27;
		}
	}
	&-legend{
		margin:0;
		padding:0;
		list-style:none;
		text-align:center;
		li{
			display:inline-block;
			margin-right:30px;
			font-size:1.1rem;
			span{
				display:inline-block;
				border:1px solid $color-gris-27;
				width:1.2rem;
				height:1.2rem;
				position:relative;
				top:1px;
				margin-right:5px;
			}
		}
		margin-bottom:20px;
	}
}
.chart-item {
    align-items: center;
    margin: 5px 0;
    min-height: 40px;

    .chart-data-container {
        display: flex;
        flex-wrap: nowrap;

        .progress-bar-container {
            width: 100%;
            position: relative;

            .left-progress-bar {
                position: absolute;
                left: -1rem;
                top: 0.3rem;
                width: 1rem;
                height: 4.5rem;
                transform: skew(0deg, 30deg);
                z-index: 5;
            }

            .top-progress-bar {
                position: relative;
                left: -0.5rem;
                transform: skew(60deg);
                padding: 0.3rem;
                z-index: 10;
            }

            .progress-bar {
                z-index: 10;

                &::before {
                    top: 0;
                }
            }
        }
    }
    padding-top: 25px;

    .chart-data-container {
        overflow: visible;
        white-space: nowrap;
    }

    .progress-bar::before {
        height: 2.5rem;
        line-height: 2.5rem;
        position: static;
    }
}
.chart-item ~ .progress-legend {
    margin-top: 25px;
}
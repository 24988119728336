.border{border:1px solid;}
.border-t{border-top:1px solid;}
.border-r{border-right:1px solid;}
.border-b{border-bottom:1px solid;}
.border-l{border-left:1px solid;}

.border-tb,
.border-bt{border-top:1px solid;border-bottom:1px solid;}
.border-rl,
.border-lr{border-right:1px solid;border-left:1px solid;}
.border-tr,
.border-rt{border-right:1px solid;border-top:1px solid;}
.border-tl,
.border-lt{border-left:1px solid;border-top:1px solid;}
.border-br,
.border-rb{border-right:1px solid;border-bottom:1px solid;}
.border-bl,
.border-lb{border-left:1px solid;border-bottom:1px solid;}

.border-trb,
.border-tbr,
.border-rtb,
.border-rbt,
.border-brt,
.border-btr,{border-top:1px solid;border-bottom:1px solid;border-right:1px solid;}

.border-tlb,
.border-tbl,
.border-ltb,
.border-lbt,
.border-blt,
.border-btl,{border-top:1px solid;border-bottom:1px solid;border-left:1px solid;}

.border-trl,
.border-tlr,
.border-ltr,
.border-lrt,
.border-rlt,
.border-rtl,{border-top:1px solid;border-right:1px solid;border-left:1px solid;}

.border-brl,
.border-blr,
.border-lbr,
.border-lrb,
.border-rlb,
.border-rbl,{border-bottom:1px solid;border-right:1px solid;border-left:1px solid;}

.border-big{border-width:4px !important;}
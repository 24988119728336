.footer{
	width:100%;
	background:$color-gris-35;
	height:2rem;
	line-height:2rem;
	font-size:1rem;
	padding:0 5px;
	p{
		margin-bottom:0;
	}
}
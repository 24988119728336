.uppercase{text-transform:uppercase !important;}

.is-closed{
	display:none;
}
.is-open{
	display:block;
	&.inline{
		display:inline;
	}
	&.inline-block{
		display:inline-block;
	}
	&.flex{
		display:flex;
	}
	&.inline-flex{
		display:inline-flex;
	}
}

.fontello-base{
	@extend [class^="icon-"]:before;
}

.valign-top{vertical-align:top !important;}
.valign-center{vertical-align:middle !important; align-self:center;}
.no-padding{padding: 0 0 0 0 !important;}

.small{font-size:1rem;}
.big{font-size:1.4rem;}

.w-auto{width:auto; flex-grow:1;}

ul.no-style, .no-style{margin:0; padding:0; list-style:none; border:none; background:none;}

.flex-label{
	display:flex;
	align-items:flex-start;
	margin-bottom:20px;
	.label{
		text-align:right;
		padding-right:15px;
		flex:0 0 240px;
		float:none;
		& + span{
			align-self:flex-start;
		}
	}
}

@import "utilities/width-height";
.Toastify__toast--success { 
    background: $color-vert-apave;
}
.Toastify__toast--error { 
    background: $color-error;
}

section:has(> .message-aboved-blurred-area) {
    position: relative;
}

.message-aboved-blurred-area {
    z-index: 9999;
    position: absolute;
    backdrop-filter: blur(3px);
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: column wrap;
}
.message-aboved-blurred-area div {
    width: 100%;
    font-size: 2rem;
    font-weight: bold;
}
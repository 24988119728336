.arborescence{
	margin:0;
	padding:0;
	list-style:none;
	position:relative;
	ul{
		list-style:none;
		padding-left:25px;
	}
	li{
		.highlight{
			display:inline-block;
		}
		.highlight:hover{
			background-color:$color-vert-apave;
			& > .info{
				background-color:$color-vert-apave;
			}
			a {
				color: $color-blanc;
			}
		}
		&:before{
			@extend .fontello-base;
			@extend .icon-right-open;
		}
		&.squared{
			&:before{
				content:'';
				display:inline-block;
				width:9px;
				height:9px;
				background-color:$color-gris-texte;
				border-radius:2px;
			}
		}
		label{
			display:inline-block;
		}
	}
	&.no-arrow{
		li {	
			&:before{
				display:none;
			}
			&.squared{
				&:before{
					display:inline-block;
				}
			}
		}
	}
	&.squared{
		li{
			&:before{
				content:'';
				display:inline-block;
				width:9px;
				height:9px;
				background-color:$color-gris-texte;
				border-radius:2px;
			}
		}
	}
	.btn{
		width:18px;
		height:18px;
		padding:0;
		margin-right:7px;
		i{
			font-size:7px;
			position:relative;
		}
	}
	.at-left{
		position:absolute;
		left:0;
		label{
			margin-right:0;
		}
	}
	.info{
		position:absolute;
		left:0;
		padding-top:5px;
	}
	button.toggler{
		&:before{
			@extend .icon-down-open;
			font-size:100%;
			top:0;

		}
		&.actif{
			&:before{
				left:-1px;
			}
		}
	}
}

.form .arborescence fieldset{
	margin-bottom:0;
	display:inline-flex;
	&.at-left{
		position:absolute;
		left:135px;
	}
	& > div{
		margin:0 0 0 0;
		label{
			margin-right:0;
			position:relative;
			top:5px;
			&:before{
				margin-left:0;
				position:relative;
				top:1px;
			}
		}
	}
}

.form .arborescence.accounttree {
	li {
		border-left:thin dashed $color-vert-apave;
		padding:0 7px;

		&:before{
			position:relative;
			top:-0.3em;
			height:1em;
			width:12px;
			color:white;
			border-bottom:thin dashed $color-vert-apave;
			content:"";
			display:inline-block;
			left:-7px;
		}
	}
}

.form .arborescence.domaintree {
	& > li {
		& > label {
			font-size: 1.5rem;
		}
		& > ul > li {
			& > ul > li {
				& > label {
					font-size: 1.1rem;
				}
			}
		}
	}
	ul {
		padding-left:45px;
	}
	li {
		border-left:thin dashed $color-vert-apave;
		padding:0 15px;

		button {
			position:absolute;
			margin-left:-15px;
			margin-top:3px;
		}

		&:before{
			position:relative;
			top:-0.3em;
			height:1.5em;
			width:30px;
			color:white;
			border-bottom:thin dashed $color-vert-apave;
			content:"";
			display:inline-block;
			left:-13px;
		}

		&.childrenselected > label {
			font-style: italic;
			color: $color-vert-apave;
		}
	}
}

.form .arborescence.pushtree {
	fieldset {
		&.at-left {
			left:0px;
		}
		&.at-right {
			position: absolute;
			display: inline-block;
			right: 0px;
		}
	}
	li {
		border-left:thin dashed $color-vert-apave;
		padding:0 7px;

		&:before{
			position:relative;
			top:-0.3em;
			height:1em;
			width:12px;
			color:white;
			border-bottom:thin dashed $color-vert-apave;
			content:"";
			display:inline-block;
			left:-7px;
		}
	}
}

.arborescence.sectiontree {
    li:first-child {
        font-weight: bold;
    }
    @for $i from 0 to 15 {
        li:nth-child(#{$i + 1}) { 
            margin-left: #{$i * 20}px; 
        }
    }
}
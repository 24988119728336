.main-nav{
	background:$color-gris-texte;
	color:$color-blanc;
	width:40px;
	overflow:auto;
	position:absolute;
	top:55px;
	left:0;
	bottom:0;
	transition:left 0.5s ease-in, width 0.5s ease-in;
	& > ul{
		width:281px;
		margin:0;
		padding:0;
		list-style:none;
		border-top:1px solid $color-blanc;
		& > li{
			margin:0;
			padding:0;
			display:block;
			border-bottom:1px solid $color-blanc;
			height:4rem;
			line-height:4rem;
			position:relative;
			& > a{
				display:block;
				border-left:4px solid $color-gris-texte;
				width:100%;
				height:100%;
				font-size:1.5rem;
				text-transform:uppercase;
				color:$color-blanc;
				background:transparent no-repeat 6px 50%;
				i{
					font-size:2rem;
					margin:0 2px;
					.badge {
						position: absolute;
						bottom: 2px;
						left: 2px;
						font-size: 10px;
					}
				}
				&:hover,
				&:focus{
					border-color:$color-gris-50;
					text-decoration:none;
					& + .submenu-toggler{
						display:inline-block;
					}
				}
			}
			.submenu-toggler{
				position:absolute;
				right:7px;
				top:11px;
				font-size:1.5rem;
				border:none;
				background:transparent;
				color:$color-gris-50;
				margin:0;
				padding:0;
				line-height:2rem;
				display:none;
			}
			&.hassub{
				& > ul{
					display:none;
					line-height:4rem;
					font-size:1.5rem;
					list-style:none;
					margin:0;
					padding:0 0 0 36px;
					& > li{
						& > a{
							color:$color-blanc;
							padding-left:11px;
						}
						&.act{
							a{
								font-weight:bold;
							}
						}
					}
				}
			}
			&.act{
				& > a{
					border-color:$color-vert-apave;
					font-weight:bold;
				}
				&.hassub{
					& > a{
						border-left:none;
					}
					.submenu-toggler{
						display:inline-block;
						color:$color-vert-apave;
					}
					border-left:4px solid $color-vert-apave;
				}
				.submenu-toggler{
					transform:rotate(90deg);
				}
			}
		}
	}
}

.main-nav-opened{
	.main-nav{
		width:281px;
		transition:left 0.5s ease-out, width 0.5s ease-out;
		> ul{
			& > li.hassub{
				&.act{
					& > ul{
						display:block;
					}
					height:auto;
				}
			}
		}
		
	}
}
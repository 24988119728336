.content{
	position:absolute;
	overflow:auto;
	top:55px;
	left:40px;
	right:0;
	bottom:0;
	border-top:0.5px solid #95989A;
	transition:left 0.5s ease-in, right 0.5s ease-in;
	scroll-behavior:smooth;
	@import "content/ariane";
	@import "content/footer";
}

.main-nav-opened{
	.content{
		left:281px;
		/* Réactiver si l'on souhaites que le contenu dépassent le cadre de la page */
		/*right:-281px;*/
		transition:left 0.5s ease-out, right 0.5s ease-out;
	}
}
.page-header{
	height:5.5rem;
	background:$color-blanc;
	display:flex;
	position:fixed;
	top:0;
	left:0;
	z-index:10;
	width:100%;
	@import "header/main-menu-toggler";
	@import "header/bandeau";
	@import "header/tools";
}
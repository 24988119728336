.ReactModalPortal {
    position:fixed;
    z-index:10;
    
    .ReactModal__Overlay {
        background-color:rgba(0, 0, 0, 0.90);
        overflow: scroll;
    }
    .ReactModal__Content {
        top:'65px';
        left:'50%';
        right:'auto';
        bottom:'auto';
        margin-right:'-50%';
        transform:'translate(-50%, 0%)';
        width:'90%';
        height:'90%';
        & > div {
            padding-bottom:20px;
        }
    }
}
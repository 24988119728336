.push-selection {
    list-style: none;
    margin: 10px 0px;
    padding: 0px;
    & > li {
        margin: 0px 0px 5px;
        display: flex;
        & > ul {
            list-style: none;
            margin: 0px 10px;
            padding: 0px;
            & > li {
                margin: 0px 0px 5px;
            }
        }
    }
}
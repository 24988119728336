.grid{
	justify-content:center;
	& > div{
		display:flex;
		align-items:stretch;
		justify-content:stretch;
	}
	h1{
		margin:40px 0;
		font-size:2rem;
		text-transform:uppercase;
	}
	h2 {
		text-align:center;
		font-size:2rem;
		font-weight:normal;
		text-transform:uppercase;
		margin-bottom:1.5rem;
		i{
			font-size:40px;	
			width:100%;
			display:block;
			margin-bottom:15px;
		}
		
	}
	.padding{
		padding:20px;
	}
	.contenu{
		background:$color-blanc;
		margin-bottom:25px;
		flex-grow:1;
		& > a{
			display:block;
			color:$color-gris-texte;
			width:100%;
			height:100%;
			text-transform:uppercase;
			font-size:1.5rem;
			text-align:center;
			font-weight:500;
			& > i:first-child{
				display:block;
				font-size:40px;
				width:100%;
			}
			& > span{
				display:block;
				font-size:1.2rem;
				font-weight:normal;
			}
			&:hover,
			&:focus{
				color:$color-vert-apave;
				text-decoration:none;
			}
		}
		&.bg-vert-apave{
			& > a{
				color:$color-blanc;
				&:hover,
				&:focus{
					background:$color-blanc;
					color:$color-vert-apave;
					text-decoration:none;
				}
			}
		}
	}
}
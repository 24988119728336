.infobulle{
	position:relative;
	&:after{
		display:none;
		position:absolute;
		content:attr(data-info);
		right:100%;
		border-radius:4px;
		background:$color-blanc;
		color:$color-gris-texte;
		border:1px solid $color-gris-texte;
		font-size:1rem;
		top:2px;
		margin-right:2px;
		padding:2px 4px;
		white-space: nowrap;
	}
	&:hover{
		&:after{
			display:inline-block;
		}
	}
	&.top:after{
		right:unset;
		top:unset;
		bottom:110%;
	}
	&.bottom:after{
		right:unset;
		top:unset;
		top:110%;
	}
	&.right:after{
		right:unset;
		top:unset;
		left:105%;
	}
}
& > .bandeau{
	padding:0 10px 0 17px;
	flex-grow:1;
	display:flex;
	align-items:stretch;
	& > div{
		display:flex;
		align-items:center;
	}
	& > .main-logo{
		padding-right:10px;
	}
	& > .main-name{
		padding-right:36px;
		font-size:1.5rem;
		text-transform:uppercase;
		color:$color-gris-50;
	}
	& > .client-logo{
		padding-left:36px;
		padding-right:14px;
		position:relative;
		&:before{
			content:"";
			display:inline-block;
			width:1px;
			height:2.8rem;
			background:$color-gris-30;
			position:absolute;
			left:0;
		}
	}
	& > .client-name{
		font-size:1.5rem;
		text-transform:uppercase;
		color:$color-gris-50;
		flex-grow:1;
	}
	& > .tools{
		justify-self:flex-end;
	}
}
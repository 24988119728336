.tools{
	display:flex;
	flex-grow:1;
	justify-content:flex-end;
	.help{
		color:$color-gris-60;
		margin-right:21px;
	}
	i{
		font-size:1.8rem;
	}
	.user-menu{
		&:before{
			@extend .fontello-base;
			@extend .icon-utilisateur-tete;
			margin-right:9px;
			font-size:1.8rem;
		}
		width:215px;
		display:flex;
		height:55px;
		overflow:visible;
		flex-wrap:wrap;
		align-items:center;
		h3{
			font-size:1.1rem;
			height:55px;
			margin-bottom:0;
			display:flex;
			align-items:center;
			justify-content:center;
			font-weight:bold;
			span{
				margin-left:4px;
				text-transform:uppercase;
			}
		}
		ul{
			font-size:1.1rem;
			margin:0;
			padding:0;
			background:$color-gris-10;
			flex:0 0 100%;
			list-style:none;
			margin-top:1px;
			display:none;
			border-width:0 1px;
			position:relative;
			left:-8px;
			li{
				width:100%;
				border:1px solid $color-gris-25;
				border-width:1px 1px 0 1px;
				min-height:30px;
				a{
					display:block;
					padding:10px;
					color:$color-gris-60;
					&:hover,
					&:focus{
						color:$color-vert-apave;
						text-decoration:none;
					}
				}
				&:first-child{
					border-top:none;
				}
				&:last-child{
					background:$color-gris-60;
					border-left:none;
					border-right:none;
					a{
						color:$color-blanc;
						&:hover,
						&:focus{
							text-decoration:underline;
						}
					}
				}
			}
		}
		&:hover{
			ul{
				display:block;
			}
		}
	}
}
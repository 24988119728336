html{
	font-family: 'Open Sans', Arial,Helvetica Neue,Helvetica,sans-serif;
	font-size:62.5%;
}

h1{
	font-size:1.8rem;
	text-align:center;
	padding:20px 0 18px 0;
}

.main-title{
	margin:40px 0 !important;
	text-transform:uppercase;
}

h3{
	font-size:1.8rem;
	font-weight:bold;
}

b, strong{
	font-weight:bold;
}

button{
	color:inherit;
}

blockquote {
	border-left: solid $color-gris-60 3px;
	padding-left: 15px;
}
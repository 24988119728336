ul.statut{
	margin:0; padding:0;
	display:inline-block;
	&.border{
		border:none  !important;
		li{
			border:1px solid $color-gris-60;
		}
	}
	li{
		display:inline-block;
		background:$color-gris-40;
		text-align:center;
		color:$color-blanc;
		font-size:1.1rem;
		margin:0 1px 1px 0;
		width:auto;
		height:2.2rem;
		line-height:2.2rem;
		padding:0px 7px;
		&:last-child{
			margin-right:0;
		}
	}
	&.small{
		border:none !important;
		li{
			width:1.6rem;
			height:1.6rem;
			line-height:1.6rem;
			padding:0 0;
		}
	}
	&.medium{
		border:none !important;
		li{
			width:6.2rem;
			height:2.2rem;
			line-height:2.2rem;
			padding:0 0;
		}
	}
	&.large{
		border:none !important;
		li{
			min-width:8.4rem;
			height:2.2rem;
			line-height:2.2rem;
			padding-left:1rem;
            padding-right:1rem;
			margin-right:5px;
			margin-bottom:5px;
			white-space:nowrap;
		}
	}
	&.extra-large{
		border:none !important;
		li{
			min-width:15rem;
			height:2.2rem;
			line-height:2.2rem;
			padding-left:1rem;
            padding-right:1rem;
			margin-right:5px;
			margin-bottom:5px;
		}
	}
}

span.statut{
	display:inline-block;
	background:$color-gris-40;
	height:2.2rem;
	line-height:1.7rem;
	padding:0px 7px;
	margin:0 1px 1px 0;
	color:$color-blanc;
	text-align:center;
	&.border{
		border:1px solid $color-gris-60 !important;
		&.big{
			border-width:2px !important;
		}
	}
	&.small{
		width:1.6rem;
		height:1.6rem;
		line-height:1.6rem;
	}
	&.medium{
		width:6.2rem;
		height:2.2rem;
		line-height:2.2rem;
	}
	&.large{
		width:8.4rem;
		height:2.2rem;
		line-height:2.2rem;
	}
}

a.statut{
	@extend span.statut;
	display:flex;
	flex-basis:50%;
	flex-wrap:wrap;
	justify-content:center;
	padding:4px 0;
	position:relative;
	margin-bottom:7px;
	& > span{
		flex:0 0 100%;
	}
	& > strong{
		flex-grow:1;
	}
	& > i{
		position:absolute;
		bottom:2px;
		right:2px;
		color:$color-blanc;
	}
	&:hover,
	&:focus{
		text-decoration:none;
	}
}
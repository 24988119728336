.table {
	margin-bottom:0;
	tr{
		td,
		th{
			vertical-align:middle;
		}
		th{
			background:$color-gris-45;
			color:$color-blanc;
		}
		th[scope="row"]{
			background:transparent;
			color:$color-gris-45;
		}
		input[type="checkbox"],
		input[type="radio"]{
			+ label{
				margin-right:0;
				color:$color-gris-45;
				&:before{
					margin-left:0;
					margin-right:0;
				}
			}
		}
		.col-checksingle-field {
			input[type="checkbox"] + label {
				background-color: $color-blanc;
				padding: 0 10px;
				margin: 4px 0;
				line-height: 3rem;
			}
		}
	}
	i{
		font-size:1.5rem;
		color:$color-gris-50;
		&:before{
			width:auto;
		}
	}
	p {
		i {
			font-size:1.2rem;
			color:$color-gris-texte;			
		}
	}
	*:last-child{
		margin-bottom:0;
	}
	button.toggler{
		font-size:1rem;
		&:before{
			margin-right:0;
		}
	}
	div[class*="border"]{
		padding-top:0;
		padding-bottom:0;
	}
	&-striped{
		tbody tr:nth-of-type(odd){
			background-color:$color-gris-10;
		}
	}
}

.cell-padding{
	padding:0.75rem !important;
}

.table.scrollable {
	overflow-x: auto;
	white-space: nowrap;
	display: block;
}

.table-striped.table-striped-contrast {
	tbody tr:nth-of-type(2n+1) {
		background-color:$color-gris-25;
	}
}

.table-bordered {
	tr.bg-gris-25 {
		th, td {
			border-color:$color-gris-30; 
		}
	}
}	
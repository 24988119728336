.item-list{
	list-style:none;
	margin:20px 0;
	padding:0;
	display:flex;
	flex-direction:column;
	&.columns{
		flex-direction:row;
		li{
			margin:0 10px;
		}
	}
	&.striped{
		li{
			padding:10px;
			& > *:first-child{
				padding-left:0;
			}
			& > *:last-child{
				padding-right:0;
			}
		}
		li:nth-child(even){
			background:$color-gris-10;
		}
	}
	li{
		flex-grow:1;
		display:flex;
		.statuts{
			flex:0 0 80px;
			padding:3px 4px;
		}
		span.statut{
			height:1.5rem;
			line-height:1.5rem;
			border-radius:4px 0 0 4px;
			width:100%;
		}
		.picto{
			padding:0 8px;
			font-size:2rem;
			text-align:center;
		}
		.contenu{
			font-size:1.5rem;
			display:flex;
			flex-grow:1;
			padding:0 4px;
			background:transparent;
			margin-bottom:0;
			flex-direction:row;
			justify-content:flex-start;
			.image{
				margin-right:15px;
			}
			.infos{
				& > *:last-child{
					margin-bottom:0;
				}
			}
		}
		a{
			text-decoration:underline;
			color:$color-gris-texte;
			&:hover,
			&:focus{
				color:$color-vert-apave;	
			}
		}
		.detail{
			a{
				color:$color-gris-40;
				font-size:15px;
				&:hover,
				&:focus{
					color:$color-vert-apave;
				}
			}
		}
		margin-bottom:7px;
	}
	&.separator{
		li{
			margin-bottom:20px;
			.contenu{
				border-bottom:2px solid $color-gris-20;
				padding-bottom:20px;
			}
			a{
				color:$color-vert-apave;
			}
		}
	}
	&.latest{
		.contenu{
			font-size:inherit;
			padding:0;
		}
	}
}
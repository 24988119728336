.ariane{
	padding-left:20px;
	height:4.6rem;
	line-height:4.6rem;
	font-size:1.1rem;
	color:$color-gris-50;
	a{
		color:$color-gris-50;
	}
	span{
		color:$color-vert-apave;
	}
}
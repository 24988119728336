@font-face {
  font-family: 'aviseinone';
  src: url('../../fonts/fontello/font/aviseinone.eot?11850026');
  src: url('../../fonts/fontello/font/aviseinone.eot?11850026#iefix') format('embedded-opentype'),
       url('../../fonts/fontello/font/aviseinone.woff2?11850026') format('woff2'),
       url('../../fonts/fontello/font/aviseinone.woff?11850026') format('woff'),
       url('../../fonts/fontello/font/aviseinone.ttf?11850026') format('truetype'),
       url('../../fonts/fontello/font/aviseinone.svg?11850026#aviseinone') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'aviseinone';
    src: url('../../fonts/fontello/font/aviseinone.svg?11850026#aviseinone') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "aviseinone";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-mail:before { content: '\e800'; } /* '' */
.icon-wrench:before { content: '\e801'; } /* '' */
.icon-chart-bar:before { content: '\e802'; } /* '' */
.icon-accueil-conformite:before { content: '\e803'; } /* '' */
.icon-accueil-consultants-compte:before { content: '\e804'; } /* '' */
.icon-accueil-dernieres-actions:before { content: '\e805'; } /* '' */
.icon-accueil-dernieres-actualites:before { content: '\e806'; } /* '' */
.icon-accueil-dernieres-questions-hotline:before { content: '\e807'; } /* '' */
.icon-actions-affecter:before { content: '\e808'; } /* '' */
.icon-actions-ajouter-favorits:before { content: '\e809'; } /* '' */
.icon-actions-ajouter-retirer-depeche:before { content: '\e80a'; } /* '' */
.icon-actions-archiver-fiche:before { content: '\e80b'; } /* '' */
.icon-actions-auto-affectation:before { content: '\e80c'; } /* '' */
.icon-actions-consulter-fiche:before { content: '\e80d'; } /* '' */
.icon-actions-consulter-pdf:before { content: '\e80e'; } /* '' */
.icon-actions-consulter:before { content: '\e80f'; } /* '' */
.icon-actions-desactiver-utilisateur:before { content: '\e810'; } /* '' */
.icon-actions-exclure-fiche-push:before { content: '\e811'; } /* '' */
.icon-actions-modifier-commentaire:before { content: '\e812'; } /* '' */
.icon-actions-modifier-utilisateur:before { content: '\e813'; } /* '' */
.icon-actions-modifier:before { content: '\e814'; } /* '' */
.icon-actions-points-veille-affectes:before { content: '\e815'; } /* '' */
.icon-actions-points-veille-non-affectes:before { content: '\e816'; } /* '' */
.icon-actions-relancer-utilisateur:before { content: '\e817'; } /* '' */
.icon-accueil-gestion-conformite:before { content: '\e818'; } /* '' */
.icon-actions-supprimer:before { content: '\e819'; } /* '' */
.icon-actions-traitement-mpv:before { content: '\e81a'; } /* '' */
.icon-autres-affirmatif:before { content: '\e81b'; } /* '' */
.icon-autres-ajoute-depeche:before { content: '\e81c'; } /* '' */
.icon-autres-documentaliste-affectee:before { content: '\e81d'; } /* '' */
.icon-autres-documentaliste-non-affectee:before { content: '\e81e'; } /* '' */
.icon-autres-expert-affecte:before { content: '\e81f'; } /* '' */
.icon-autres-expert-non-affecte:before { content: '\e820'; } /* '' */
.icon-autres-fiche-privative:before { content: '\e821'; } /* '' */
.icon-autres-lien-fiche:before { content: '\e822'; } /* '' */
.icon-autres-non-ajoute-depeche:before { content: '\e823'; } /* '' */
.icon-autres-temps-depasse:before { content: '\e824'; } /* '' */
.icon-autres-texte-majeur:before { content: '\e825'; } /* '' */
.icon-boutons-acces-plan-action:before { content: '\e826'; } /* '' */
.icon-boutons-affecter-utilisateur:before { content: '\e827'; } /* '' */
.icon-boutons-afficher-favoris:before { content: '\e828'; } /* '' */
.icon-boutons-ajouter-creer-affecter:before { content: '\e829'; } /* '' */
.icon-boutons-archiver:before { content: '\e82a'; } /* '' */
.icon-boutons-creer-acces:before { content: '\e82b'; } /* '' */
.icon-boutons-creer-exigence:before { content: '\e82c'; } /* '' */
.icon-boutons-creer-fiche-privative:before { content: '\e82d'; } /* '' */
.icon-boutons-enregistrer-recherche:before { content: '\e82e'; } /* '' */
.icon-boutons-enregistrer:before { content: '\e82f'; } /* '' */
.icon-boutons-export-pdf:before { content: '\e830'; } /* '' */
.icon-boutons-exporter:before { content: '\e831'; } /* '' */
.icon-boutons-lien-vers:before { content: '\e832'; } /* '' */
.icon-boutons-moins:before { content: '\e833'; } /* '' */
.icon-boutons-plus:before { content: '\e834'; } /* '' */
.icon-boutons-poubelle:before { content: '\e835'; } /* '' */
.icon-boutons-recherches-enregistrees:before { content: '\e836'; } /* '' */
.icon-boutons-traitement-mpv:before { content: '\e837'; } /* '' */
.icon-boutons-transferer:before { content: '\e838'; } /* '' */
.icon-boutons-voir-historique:before { content: '\e839'; } /* '' */
.icon-documentaire-actualites-documentaires:before { content: '\e83a'; } /* '' */
.icon-documentaire-controles-reglementaires:before { content: '\e83b'; } /* '' */
.icon-documentaire-dossiers-thematiques:before { content: '\e83c'; } /* '' */
.icon-documentaire-flash-informations:before { content: '\e83d'; } /* '' */
.icon-documentaire-formulatires-declaration:before { content: '\e83e'; } /* '' */
.icon-documentaire-jurisprudence:before { content: '\e83f'; } /* '' */
.icon-documentaire-lien:before { content: '\e840'; } /* '' */
.icon-documentaire-module-prive-client:before { content: '\e841'; } /* '' */
.icon-documentaire-newsletter:before { content: '\e842'; } /* '' */
.icon-documentaire-organigrammes-reglementaires:before { content: '\e843'; } /* '' */
.icon-documentaire-projet-texte:before { content: '\e844'; } /* '' */
.icon-accueil-dernieres-fiches:before { content: '\e845'; } /* '' */
.icon-documentaire-sanctions:before { content: '\e846'; } /* '' */
.icon-filtres-calendrier:before { content: '\e847'; } /* '' */
.icon-filtres-fichier:before { content: '\e848'; } /* '' */
.icon-filtres-poubelle:before { content: '\e849'; } /* '' */
.icon-filtres-vider-champ:before { content: '\e84a'; } /* '' */
.icon-general-carat:before { content: '\e84b'; } /* '' */
.icon-nav-accueil:before { content: '\e84c'; } /* '' */
.icon-nav-arbo-point-veille:before { content: '\e84d'; } /* '' */
.icon-nav-burger:before { content: '\e84e'; } /* '' */
.icon-nav-espace-documentaire:before { content: '\e84f'; } /* '' */
.icon-help-circled:before { content: '\e850'; } /* '' */
.icon-nav-ma-hotline:before { content: '\e851'; } /* '' */
.icon-nav-ma-veille:before { content: '\e852'; } /* '' */
.icon-nav-mes-favorites:before { content: '\e853'; } /* '' */
.icon-nav-mes-parametres:before { content: '\e854'; } /* '' */
.icon-nav-mon-plan-actions:before { content: '\e855'; } /* '' */
.icon-nav-recherche-texte:before { content: '\e856'; } /* '' */
.icon-utilisateur-poininterrogation:before { content: '\e857'; } /* '' */
.icon-utilisateur-tete:before { content: '\e858'; } /* '' */
.icon-down-open:before { content: '\e859'; } /* '' */
.icon-nav-gestion-categories:before { content: '\e85a'; } /* '' */
.icon-right-open:before { content: '\e85b'; } /* '' */
.icon-left-open:before { content: '\e85c'; } /* '' */
.icon-up-open:before { content: '\e85d'; } /* '' */
.icon-actions-plan-action:before { content: '\e85e'; } /* '' */
.icon-documentaire-recherche:before { content: '\e85f'; } /* '' */
.icon-actions-retenir-fiche:before { content: '\e860'; } /* '' */
.icon-th-list:before { content: '\e861'; } /* '' */
.icon-check-empty-1:before { content: '\e862'; } /* '' */
.icon-nav-utilisateur-interne:before { content: '\e863'; } /* '' */
.icon-nav-utilisateur-externe:before { content: '\e864'; } /* '' */
.icon-nav-historique:before { content: '\e865'; } /* '' */
.icon-check:before { content: '\e866'; } /* '' */
.icon-check-empty:before { content: '\f096'; } /* '' */
.icon-tasks:before { content: '\f0ae'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-ok-squared:before { content: '\f14a'; } /* '' */
.icon-dot-circled:before { content: '\f192'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
